<template>
  <div class="account-menu">
    <div class="account-menu__wrapper">
      <router-link :to="`${$locale.base}/account`">
        <Row class="account-menu__user">
          <Column class="account-menu__avatar">
            <UserAvatar :params="{avatar: $user.avatar, size: $laptop ? 50 : 40}"/>
          </Column>
          <Column justify="center">
            <router-link :to="`${$locale.base}/account`"
                         class="account-menu__title">{{ $t('account.my_profile') }}
            </router-link>
            <p class="account-menu__email">{{$user.email}}</p>
          </Column>
        </Row>
      </router-link>
      <transition name="fade"
                  mode="out-in">
        <TourCompanyMenu v-if="$agency && $my.agencies"
                         :params="{id: $agency.id,
                          title: $agency.title,
                          logo: $agency.logo,
                          outOfList: true}"/>
      </transition>
      <ul class="account-menu__list">
        <li :key="item.id"
            class="account-menu__list-item"
            v-for="item in list">
          <router-link :to="`${$locale.base}${item.href}`"
                       class="account-menu__list-item-link"
                       :class="item.href === $route.path ?
                       'account-menu__list-item-active' : ''">
            {{item.text}}
          </router-link>
        </li>
      </ul>
      <Divider/>
      <transition name="fade"
                  mode="out-in">
        <Loader v-if="!ready"
                :params="{style: {width: '100%',height: '200px',margin: '20px 0'}}"/>
        <div v-else
             class="account-menu__tour-companies">
          <TourCompanyMenu v-for="item in $my.agencies"
                           :key="item.id"
                           :params="{id: item.id,
                           title: item.title,
                           logo: item.logo}"/>
        </div>
      </transition>
      <Divider/>
      <Row class="account-menu__exit-button">
        <div @click="logout"
             class="account-menu__button">
          <Icon class="account-menu__icon"
                viewport="0 0 20 20"
                xlink="logout"/>
          <span>{{ $t('account.exit') }}</span>
        </div>
      </Row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountMenu',
  data() {
    return {
      ready: false,
      list: [
        {
          href: '/account/favorites',
          text: this.$i18n.t('account.wanna_visit'),
        },
        {
          href: '/account/excursions',
          text: this.$i18n.t('account.my_excursions'),
        },
        {
          href: '/account/places',
          text: this.$i18n.t('account.my_obj'),
        },
        {
          href: '/account/agencies',
          text: this.$i18n.t('account.my_org'),
        },
      ],
    };
  },
  created() {
    if (this.$my.agencies.length === 0) {
      this.$http.get(`/api/agencies?user=current&count=100500&lang=${this.$i18n.locale}`)
        .then((resolve) => {
          this.$store.commit('SET_MY_AGENCIES', resolve.data.data);
          this.ready = true;
        });
    } else {
      this.ready = true;
    }
  },
  methods: {
    logout() {
      window.location = `/logout?api_token=${this.$token}`;
    },
  },
};
</script>
