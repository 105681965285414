var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-menu"},[_c('div',{staticClass:"account-menu__wrapper"},[_c('router-link',{attrs:{"to":`${_vm.$locale.base}/account`}},[_c('Row',{staticClass:"account-menu__user"},[_c('Column',{staticClass:"account-menu__avatar"},[_c('UserAvatar',{attrs:{"params":{avatar: _vm.$user.avatar, size: _vm.$laptop ? 50 : 40}}})],1),_c('Column',{attrs:{"justify":"center"}},[_c('router-link',{staticClass:"account-menu__title",attrs:{"to":`${_vm.$locale.base}/account`}},[_vm._v(_vm._s(_vm.$t('account.my_profile'))+" ")]),_c('p',{staticClass:"account-menu__email"},[_vm._v(_vm._s(_vm.$user.email))])],1)],1)],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$agency && _vm.$my.agencies)?_c('TourCompanyMenu',{attrs:{"params":{id: _vm.$agency.id,
                        title: _vm.$agency.title,
                        logo: _vm.$agency.logo,
                        outOfList: true}}}):_vm._e()],1),_c('ul',{staticClass:"account-menu__list"},_vm._l((_vm.list),function(item){return _c('li',{key:item.id,staticClass:"account-menu__list-item"},[_c('router-link',{staticClass:"account-menu__list-item-link",class:item.href === _vm.$route.path ?
                     'account-menu__list-item-active' : '',attrs:{"to":`${_vm.$locale.base}${item.href}`}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0),_c('Divider'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.ready)?_c('Loader',{attrs:{"params":{style: {width: '100%',height: '200px',margin: '20px 0'}}}}):_c('div',{staticClass:"account-menu__tour-companies"},_vm._l((_vm.$my.agencies),function(item){return _c('TourCompanyMenu',{key:item.id,attrs:{"params":{id: item.id,
                         title: item.title,
                         logo: item.logo}}})}),1)],1),_c('Divider'),_c('Row',{staticClass:"account-menu__exit-button"},[_c('div',{staticClass:"account-menu__button",on:{"click":_vm.logout}},[_c('Icon',{staticClass:"account-menu__icon",attrs:{"viewport":"0 0 20 20","xlink":"logout"}}),_c('span',[_vm._v(_vm._s(_vm.$t('account.exit')))])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }